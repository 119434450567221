import React, { useState } from "react";
import { useSelector } from "react-redux";

import SmallPagePanel from "~src/components/layout/SmallPagePanel";
import CatchCardReward from "~src/components/shared/onboarding/CatchCardReward";
import LinkDebit from "~src/components/shared/onboarding/LinkDebit";
import { selectClaims } from "~src/store";

type LinkPaymentProps = {
  onNext: () => void;
};

const LinkPayment: React.VFC<LinkPaymentProps> = ({ onNext }) => {
  const { newUserOnboardingRewardCampaign } = useSelector(selectClaims);
  const [hideIntro, setHideIntro] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  return (
    <SmallPagePanel
      title={hideIntro ? "" : "Link your existing debit card"}
      subtitle={
        hideIntro
          ? ""
          : "The Catch card is like an extension to your existing debit card. We'll charge your linked debit card when you pay with your Catch card."
      }
      icon={
        newUserOnboardingRewardCampaign ? (
          <CatchCardReward
            reward={newUserOnboardingRewardCampaign}
            percentComplete={57}
          />
        ) : (
          <></>
        )
      }
    >
      <LinkDebit
        onError={setHideIntro}
        onEdit={setIsEditing}
        isEditing={isEditing}
        onSuccess={onNext}
      />
    </SmallPagePanel>
  );
};

export default LinkPayment;
