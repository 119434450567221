import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useTrackPageView } from "~common/tracking";
import { selectCatchCard } from "~src/store";

import Confirmation from "./Confirmation";
import Education from "./Education";
import IdentityVerification from "./IdentityVerification";
import LinkPayment from "./LinkPayment";
import PinSetup from "./PinSetup";
import Success from "./Success";

const ActivateCard: React.VFC = () => {
  const [activeScreen, setActiveScreen] = useState("education");
  const [initialScreen, setInitialScreen] = useState("debit");
  const catchCard = useSelector(selectCatchCard);

  useTrackPageView("Onboarding Activate Card");

  useEffect(() => {
    if (catchCard?.user_flow_status === "approved" && !catchCard?.is_pin_set) {
      setInitialScreen("pin");
    }
  }, [catchCard]);

  return (
    <>
      {activeScreen === "education" && (
        <Education onNext={() => setActiveScreen(initialScreen)} />
      )}

      {activeScreen === "debit" && (
        <LinkPayment onNext={() => setActiveScreen("idv")} />
      )}

      {activeScreen === "idv" && (
        <IdentityVerification
          onNext={() => setActiveScreen("confirmation")}
          onRetryDebit={() => setActiveScreen("debit")}
        />
      )}

      {activeScreen === "confirmation" && (
        <Confirmation
          onNext={() => setActiveScreen("pin")}
          open={activeScreen === "confirmation"}
        />
      )}

      {activeScreen === "pin" && (
        <PinSetup onNext={() => setActiveScreen("success")} />
      )}

      {activeScreen === "success" && <Success />}
    </>
  );
};

export default ActivateCard;
