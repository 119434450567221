import React, { useState } from "react";
import { useSelector } from "react-redux";

import { OpenInNew as OpenInNewIcon } from "@mui/icons-material";
import { Box, FormHelperText, Link, Stack } from "@mui/material";

import {
  DefaultButton,
  PrimaryButton,
} from "~common/components/controls/buttons";
import { MARQETA_ERROR_MESSAGE } from "~common/components/marqeta/marqeta";
import MarqetaCard from "~common/components/marqeta/MarqetaCard";
import { useTransactionalSms } from "~common/services/sms";
import { useTracking } from "~common/tracking";
import { ADOPTION_URLS } from "~common/utils/adoption";
import { IS_DEV } from "~src/config";
import { selectCatchCard, selectCurrentUser } from "~src/store";
import { getDevice } from "~src/utils/devices";

type CatchCardSuccessProps = {
  onClose: () => void;
  externalLink?: {
    text: string;
    link: string;
  } | null;
};

const CatchCardSuccess: React.VFC<CatchCardSuccessProps> = ({
  onClose,
  externalLink,
}) => {
  const device = getDevice();
  const { trackEvent, trackError } = useTracking();
  const { mutate: transactionalSms } = useTransactionalSms();
  const currentUser = useSelector(selectCurrentUser.data);
  const catchCard = useSelector(selectCatchCard);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  const handleClose = () => {
    trackEvent("Catch Card Success Explore Catch Clicked");
    onClose();
  };

  const handleError = () => {
    setError(MARQETA_ERROR_MESSAGE);
    trackError("CatchCardSuccess", "Marqeta bootstrap error");
  };

  const handleCopy = (name: string) => {
    trackEvent(`Catch Card Copy ${name} Clicked`);
  };

  const handleClick = async () => {
    if (device === "iphone") {
      window.location.href = ADOPTION_URLS.ios;
      return;
    }

    if (loading || !currentUser?.phone_number) {
      return;
    }

    setError("");
    setLoading(true);

    try {
      await transactionalSms({
        phone_number: currentUser?.phone_number,
        message_type: "ios_app_link",
        data: {
          singular_link: ADOPTION_URLS.ios,
        },
      });

      setLoading(false);
      setSuccess(true);
      trackEvent("Catch Card Success SMS Sent");
    } catch (err) {
      setLoading(false);
      setError("Something didn't work as expected. Please try again.");
      trackError("CatchCardSuccess", "Send SMS ", { error: err });
    }
  };

  return (
    <Stack spacing={6} alignItems="center">
      <Box
        width="100%"
        maxWidth={327}
        overflow="hidden"
        sx={({ shape }) => ({
          borderRadius: `${shape.borderRadius * 5}px`,
        })}
      >
        <MarqetaCard
          token={catchCard?.marqeta_token || ""}
          isSandbox={IS_DEV}
          onFailure={handleError}
          onCopy={handleCopy}
        />
      </Box>

      {error && <FormHelperText error>{error}</FormHelperText>}

      {device === "android" ? (
        <PrimaryButton onClick={handleClose} fullWidth>
          Explore Catch
        </PrimaryButton>
      ) : (
        <PrimaryButton
          onClick={handleClick}
          fullWidth
          disabled={success}
          loading={loading}
          sx={({ palette }) => ({
            "&.MuiButton-containedRegularButtonBackground": {
              backgroundColor: palette.common.black,
            },
            "&.MuiButton-containedRegularButtonBackground:hover": {
              backgroundColor: palette.common.black,
            },
          })}
        >
          Add to Apple Wallet in the app
        </PrimaryButton>
      )}

      {externalLink && (
        <DefaultButton href={externalLink.link} endIcon={<OpenInNewIcon />}>
          {externalLink.text}
        </DefaultButton>
      )}

      {device !== "android" && (
        <Link
          component="button"
          onClick={handleClose}
          sx={({ palette }) => ({
            color: palette.grey[500],
            fontWeight: 500,
          })}
        >
          Explore Catch
        </Link>
      )}
    </Stack>
  );
};

export default CatchCardSuccess;
