import React, { useState } from "react";
import { useSelector } from "react-redux";

import SmallPagePanel from "~src/components/layout/SmallPagePanel";
import CatchCardReward from "~src/components/shared/onboarding/CatchCardReward";
import PlaidIDVLink from "~src/components/shared/onboarding/PlaidIDVLink";
import { selectClaims } from "~src/store";

type LinkPaymentProps = {
  onNext: () => void;
  onRetryDebit: () => void;
};

const IdentityVerification: React.VFC<LinkPaymentProps> = ({
  onNext,
  onRetryDebit,
}) => {
  const { newUserOnboardingRewardCampaign } = useSelector(selectClaims);
  const [hideIntro, setHideIntro] = useState(false);

  return (
    <SmallPagePanel
      title={hideIntro ? "" : "Identity Verification"}
      subtitle={
        hideIntro
          ? ""
          : "Almost there! We're required by law to ask you a few more ID verification questions."
      }
      icon={
        newUserOnboardingRewardCampaign ? (
          <CatchCardReward
            reward={newUserOnboardingRewardCampaign}
            percentComplete={71.25}
          />
        ) : (
          <></>
        )
      }
    >
      <PlaidIDVLink
        onSuccess={onNext}
        onError={() => setHideIntro(true)}
        onRetryDebit={onRetryDebit}
        hideIntro
        showContinue
      />
    </SmallPagePanel>
  );
};

export default IdentityVerification;
