import React from "react";

import { Box, LinearProgress, Stack, Typography } from "@mui/material";

import { useMerchantbadgeUrl } from "~common/hooks/asset-hooks";
import { RewardCampaignResponseData } from "~common/services";
import { formatMoney } from "~common/utils/money";

type CatchCardRewardProps = {
  reward?: (RewardCampaignResponseData & { claimed?: boolean }) | null;
  percentComplete?: number;
  hideBackground?: boolean;
  onClick?: () => void;
};

const CatchCardReward: React.VFC<CatchCardRewardProps> = ({
  reward,
  percentComplete,
  hideBackground,
  onClick,
}) => {
  const badgeUrl = useMerchantbadgeUrl(reward?.merchant_id);

  if (!reward) {
    return null;
  }

  return (
    <Stack
      spacing={2}
      width="100%"
      pt={hideBackground ? 1.5 : 0}
      pb={hideBackground ? 1.5 : 2}
      component={onClick ? "button" : "div"}
      onClick={onClick}
      sx={({ palette }) => ({
        pointerEvents: "auto",
        background: palette.common.white,
        border: "none",
      })}
    >
      <Stack
        width="100%"
        p={2}
        direction="row"
        alignItems="flex-start"
        justifyContent={hideBackground ? "center" : undefined}
        bgcolor={hideBackground ? undefined : "tints.purple.light"}
        sx={({ shape }) => ({
          borderRadius: `${shape.borderRadius * 3}px`,
        })}
      >
        <Box
          component="img"
          src={badgeUrl}
          sx={({ palette }) => ({
            width: 48,
            height: 48,
            ...(reward.merchant_id !== "FLEX_MERCHANT_ID" && {
              mr: 2,
              ...(!hideBackground && {
                border: `3px solid ${palette.common.white}`,
                borderRadius: "50%",
              }),
            }),
          })}
        />

        <Stack>
          <Typography variant="h3">
            {reward.claimed
              ? `You have a ${formatMoney(reward.total_amount || 0, {
                  dropCentsIfRound: true,
                })} reward 🎉`
              : `Claim your ${
                  reward.merchant_id === "FLEX_MERCHANT_ID"
                    ? "Catch Cash"
                    : "welcome rewards"
                }`}
          </Typography>

          <Stack
            direction="row"
            my={1}
            sx={({ shape }) => ({
              borderRadius: `${shape.borderRadius * 2.5}px`,
              overflow: "hidden",
            })}
          >
            <Typography
              variant="bodySmall"
              fontWeight="700"
              px={2}
              bgcolor="tertiary.main"
              color="tertiary.contrastText"
            >
              {reward.claimed
                ? "Claim $20 extra in the app"
                : `${formatMoney(reward.total_amount || 0, {
                    dropCentsIfRound: true,
                  })} now`}
            </Typography>

            {!reward.claimed && (
              <Typography
                variant="bodySmall"
                fontWeight="700"
                px={2}
                bgcolor="tertiary.contrastText"
                color="tertiary.main"
                sx={({ palette }) => ({
                  position: "relative",
                  "&:after": {
                    content: "''",
                    background: `linear-gradient(105deg, ${
                      palette.tertiary.main
                    } 5px, ${palette.common.white} 5px, ${
                      palette.common.white
                    } 7px, ${palette.tertiary.contrastText || ""} 7px, ${
                      palette.tertiary.contrastText || ""
                    } 12px)`,
                    height: "100%",
                    width: 10,
                    position: "absolute",
                    left: -2.5,
                  },
                })}
              >
                $20 extra in the app
              </Typography>
            )}
          </Stack>
        </Stack>
      </Stack>

      {percentComplete && (
        <LinearProgress
          variant="determinate"
          value={percentComplete}
          sx={({ shape }) => ({
            borderRadius: `${shape.borderRadius}px`,
          })}
        />
      )}
    </Stack>
  );
};

export default CatchCardReward;
