import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Box, Divider, Link, Stack, Typography } from "@mui/material";

import { PrimaryButton } from "~common/components/controls/buttons";
import { PhoneInput } from "~common/components/controls/text-fields";
import { ResponsiveDrawerDialog } from "~common/components/modals";
import { useGetRewardCampaign } from "~common/services";
import { useTransactionalSms } from "~common/services/sms";
import { useTracking } from "~common/tracking";
import { embeddedMessenger } from "~common/utils/iframe-messaging";
import {
  isValidUSPhoneNumber,
  removePhoneNumberFormatting,
} from "~common/utils/phone-number";
import CatchCardReward from "~src/components/shared/onboarding/CatchCardReward";
import { WEBFLOW_URL } from "~src/config";
import { getDevice } from "~src/utils/devices";
import { getRewardCampaignId } from "~src/utils/reward-campaigns";

const SignUp: React.VFC = () => {
  const device = getDevice();
  const [searchParams] = useSearchParams();
  const rewardCampaignId = searchParams.get(
    "new_user_onboarding_reward_campaign_id"
  );
  const { trackEvent, trackError } = useTracking();
  const { mutate: transactionalSms } = useTransactionalSms();
  const [open, setOpen] = useState(false);
  const [phone, setPhone] = useState("");
  const [params, setParams] = useState("");
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [singularLink, setSingularLink] = useState("");

  const { data: reward } = useGetRewardCampaign({
    pathParams: {
      rewardCampaignId: getRewardCampaignId(rewardCampaignId),
    },
    lazy: !rewardCampaignId,
  });

  useEffect(() => {
    embeddedMessenger(WEBFLOW_URL, {
      CATCH_SIGNUP_OPEN: ({ data }) => {
        setSingularLink((data?.singularLink as string) || "");
        setOpen(true);
      },
    });
  }, []);

  useEffect(() => {
    if (isValidUSPhoneNumber(phone)) {
      setParams(
        `?phoneNumber=${removePhoneNumberFormatting(phone)}&skipAuthPhone=true${
          rewardCampaignId
            ? `&new_user_onboarding_reward_campaign_id=${rewardCampaignId}`
            : ""
        }`
      );
    } else {
      setParams("");
    }
  }, [phone, rewardCampaignId]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!isValidUSPhoneNumber(phone)) {
      setHasError(true);
      return;
    }

    if (device === "iphone") {
      embeddedMessenger(WEBFLOW_URL).dispatch("CATCH_SIGNUP_REDIRECT");
      return;
    }

    setLoading(true);

    try {
      await transactionalSms({
        phone_number: `+1${removePhoneNumberFormatting(phone)}`,
        message_type: "ios_app_link",
        ...(singularLink && {
          data: {
            singular_link: singularLink,
          },
        }),
      });

      setLoading(false);
      setIsComplete(true);
      trackEvent("External Sign Up SMS Sent");
    } catch (err) {
      setLoading(false);
      setHasError(true);
      trackError("External Sign Up", "Send SMS ", { error: err });
    }
  };

  const handleChange = () => {
    setPhone("");
    setIsComplete(false);
  };

  const handleClose = () => {
    setOpen(false);
    embeddedMessenger(WEBFLOW_URL).dispatch("CATCH_SIGNUP_CLOSE");
  };

  return (
    <>
      <CatchCardReward
        reward={reward}
        hideBackground
        onClick={() => setOpen(true)}
      />

      <ResponsiveDrawerDialog
        open={open}
        onClose={handleClose}
        sx={{
          ".MuiDrawer-paper > .MuiBox-root:last-of-type": {
            px: 4,
          },
        }}
      >
        {({ headingId, contentId }) => (
          <Stack
            spacing={4}
            id={contentId}
            component="form"
            onSubmit={handleSubmit}
          >
            {isComplete ? (
              <>
                <Typography id={headingId} variant="h2">
                  Did you get our text?
                </Typography>

                <Typography variant="bodyRegular">
                  A text message with a link to the app store has been sent to{" "}
                  {phone}.{" "}
                  <Link component="button" onClick={handleChange}>
                    Change
                  </Link>
                </Typography>

                <PrimaryButton type="submit" loading={loading} fullWidth>
                  Resend Code
                </PrimaryButton>
              </>
            ) : (
              <>
                <Typography
                  id={headingId}
                  variant="h2"
                  sx={{
                    fontSize: 32,
                    lineHeight: 1.25,
                  }}
                >
                  {reward?.merchant_id === "FLEX_MERCHANT_ID"
                    ? "Start earning $$ to your favorite brands."
                    : "Your rewards are a tap away"}
                </Typography>

                <CatchCardReward reward={reward} />

                <Box pt={2}>
                  <PhoneInput
                    value={phone}
                    updateValue={setPhone}
                    helperText=""
                    error={hasError}
                  />
                </Box>

                {device === "android" ? (
                  <>
                    <PrimaryButton
                      type="button"
                      href={`https://app.getcatch.com/u/${params}`}
                      fullWidth
                      target="_top"
                    >
                      Activate my card
                    </PrimaryButton>
                  </>
                ) : (
                  <>
                    <PrimaryButton type="submit" loading={loading} fullWidth>
                      Get the iOS App
                    </PrimaryButton>

                    <Link
                      alignSelf="center"
                      href={`https://app.getcatch.com/u/${params}`}
                      target="_top"
                    >
                      <Typography
                        variant="bodyRegular"
                        color="grey.500"
                        fontWeight="500"
                      >
                        Continue on web
                      </Typography>
                    </Link>

                    <Divider />

                    <Box color="grey.500" fontSize={10} lineHeight={1.5}>
                      By providing your number, you agree to receive a one-time
                      Catch SMS transactional message. See{" "}
                      <Link href="https://www.getcatch.com/terms" target="_top">
                        Terms
                      </Link>{" "}
                      for more info. Message and data rates may apply.
                    </Box>
                  </>
                )}
              </>
            )}
          </Stack>
        )}
      </ResponsiveDrawerDialog>
    </>
  );
};

export default SignUp;
