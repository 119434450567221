import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { useGetRewardCampaign } from "~common/services";
import {
  getSessionStorageWithFallback,
  setSessionStorageWithFallback,
} from "~common/utils/browser-storage";
import { setNewUserOnboardingRewardCampaign } from "~src/store/slices/user-slice";
import { getRewardCampaignId } from "~src/utils/reward-campaigns";

const useNewUserOnboardingReward = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const [rewardCampaignId, setRewardCampaignId] = useState("");

  const { data } = useGetRewardCampaign({
    pathParams: {
      rewardCampaignId: getRewardCampaignId(rewardCampaignId),
    },
    lazy: !rewardCampaignId,
  });

  useEffect(() => {
    const storedRewardCampaignId = getSessionStorageWithFallback(
      "new_user_onboarding_reward_campaign_id"
    );

    if (storedRewardCampaignId) {
      setRewardCampaignId(storedRewardCampaignId);
    }
  }, []);

  useEffect(() => {
    const rewardCampaignIdParam =
      searchParams.get("new_user_onboarding_reward_campaign_id") || "";

    if (rewardCampaignIdParam) {
      setRewardCampaignId(rewardCampaignIdParam);

      setSessionStorageWithFallback(
        "new_user_onboarding_reward_campaign_id",
        rewardCampaignIdParam
      );
    }
  }, [searchParams]);

  useEffect(() => {
    if (data) {
      dispatch(setNewUserOnboardingRewardCampaign(data));
    }
  }, [dispatch, data]);
};

export default useNewUserOnboardingReward;
